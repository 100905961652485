.landing6-container {
    /*noto sans*/
    font-family: 'Noto Sans', sans-serif;

    overflow-x: hidden;
    overflow-y: scroll;

    padding-top: 40px;

    background-color: #04041d;
}

.landing6navbar-conatiner {
    height: 50px;
    width: 80vw;

    background-color: rgb(33, 37, 37);
    /*background-color: #04041d;*/
    /*background-color: whitesmoke;*/

    border-radius: 25px;

    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;

    padding: 5px;
    padding-right: 10px;
    padding-left: 2%;

    position: fixed;
    left: 10vw;
    right: 10vw;
    top: 55px;

    z-index: 100;
}

.landing6navbar-menu-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.landing6navbar-logo-image {
    height: 30px;

    /*turn into white image*/
    filter: brightness(0) invert(1);
}

.landing6navbar-options {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8%;
}

.landing6navbar-actions {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 5%;
}

.landing6-header-menu-item {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;

    cursor: pointer;
}

.landing6-header-menu-item-link {
    color: #dcecff;
    /*color: #525252;*/
    text-decoration: none;
}

.landing6-header-menu-item:hover {
    color: #006AFF;
}

.landing6-header-menu-login-button {
    outline: none;
    border: none;

    font-size: 18px;
    font-weight: 500;

    border-radius: 20px;

    padding: 3px;
    padding-left: 30px;
    padding-right: 30px;
}

.landing6-header-menu-login-button:hover {
    cursor: pointer;
    color: #006AFF;
}

.landing6-content {
    overflow: hidden;
}

.landing6-section1-container {
    position: relative;
}

.landing6-section1-background {
    position: absolute;
    z-index: 0;

    display: flex;
    padding-left: 45vw;
    padding-top: 10vh;

    width: 100vw;
    height: 100%;

    background-color: rgba(4, 4, 29, 1);
    background-image:
        radial-gradient(circle 1000px at 5% 10%, #006aff, #0a5ab8 15%, #131832 35%, transparent 40%),
        radial-gradient(circle 1000px at 70% 40%, #0daede, #0daede 15%, transparent 40%),
        radial-gradient(circle 700px at 50% 70%, #4e3cb2, transparent 30%);
}

.landing6-section1 {

    width: 100vw;
    padding: 0 10%;
    padding-top: 8%;
    padding-bottom: 5%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    color: #dcecff;
}

.landing6-section1-body {
    z-index: 2;
}

.landing6-section1-left {
    width: 40%;

    display: flex;
    flex-direction: column;
    gap: 35px;

    z-index: 2;
}

.landing6-section1-right {
    width: 60%;

    display: flex;
    justify-content: right;
    align-items: center;

    z-index: 2;
}

.landing6-section1-left-title {
    font-size: 50px;
    line-height: 60px;

    font-weight: 700;
}

.landing6-section2-button {
    padding-left: 5%;
}

.landing6-header-menu-button {
    font-size: 18px;
    font-weight: 500;

    border-radius: 20px;
    background-color: #006AFF;
    color: white;
    border: 1px solid #006AFF;

    padding: 3px;
    padding-left: 30px;
    padding-right: 30px;

    white-space: nowrap;

    display: flex;
    align-items: center;
    gap: 5px;
}

.landing6-header-menu-button:hover {
    cursor: pointer;
    background-color: rgb(13, 69, 153);
    border-color: rgb(13, 69, 153);
}

.landing6-header-menu-button2 {
    font-size: 18px;
    font-weight: 500;

    border-radius: 20px;
    background-color: transparent;
    color: white;

    outline: none;

    white-space: nowrap;

    border: 2px solid #006aff;

    padding: 2px;
    padding-left: 30px;
    padding-right: 30px;
}

.landing6-header-menu-button2:hover {
    cursor: pointer;
    background-color: rgb(13, 69, 153);
    border-color: rgb(13, 69, 153);
}

.landing6-section1-left-subtitle {
    font-size: 24px;
    line-height: 30px;

    padding-right: 5%;
}

.landing6-section1-left-button {
    padding-top: 40px;

    display: flex;
    gap: 20px;
}

.landing6-section1-video {
    width: 90%;

    border: 10px solid aliceblue;
    border-radius: 5px;
}

.landing6-section1-logos-conatiner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-top: 1%;
    padding-bottom: 5%;

    z-index: 3;
}

.landing6-section1-logos-title {
    z-index: 2;

    font-size: 20px;
    line-height: 60px;
    font-weight: 700;

    padding-bottom: 10px;

    color: rgb(220, 236, 255);
}

.landing6-section1-logos {
    z-index: 2;

    width: 100vw;
}

.landing6-section1-logo {
    height: 30px;

    padding-left: 20px;
    padding-right: 20px;
}

.landing6-section2 {
    position: relative;

    width: 100vw;

    display: grid;
    grid-template-columns: 2fr 3fr;
    align-items: center;

    padding: 10% 0%;
}

.landing6-section2-item {
    border-top: 1px solid #043873;

    padding: 2% 5%;
}

.landing6-section2-item-title {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;

    font-size: 22px;
}

.landing6-section2-item-subtitle {
    padding-left: calc(5% + 10px);
}

.landing6-section2-left {
    width: 100%;
    padding-left: 10vw;

    color: rgb(220, 236, 255);

    z-index: 4;
}

.landing6-section2-right {
    position: relative;

    width: 100%;
}

.landing6-section2-right-body {
    padding-bottom: 20px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 10%;
}

.landing6-section2-right-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 20px;
}

.landing6-section2-bottom {
    margin-top: -2%;

    height: 200px;
}

.landing6-section2-image-container {
    z-index: 4;

    display: flex;
    justify-content: center;
    align-items: center;
}

.landing6-section2-image {
    /*margin-left: 20%;
    margin-right: 20%;

    width: 60%;

    height: 100px;
    transform: perspective(250px) rotate3D(0.5, 0, 0, 10deg);*/
    height: 70vh;

    z-index: 5;
}

.landing6-section2-image1 {
    position: relative;
    top: 25px;

    z-index: 4;
}

.landing6-section2-image2 {
    position: relative;
    top: -50px;

    z-index: 3;
}

.landing6-section2-image3 {
    position: relative;
    top: -125px;

    z-index: 2;
}

.landing6-section2-top {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.landing6-section2-top-title {
    font-size: 30px;
    line-height: 35px;

    font-weight: 600;

    text-align: left;
    padding-left: 5%;

    z-index: 4;

    color: rgb(220, 236, 255);
}

.landing6-section2-top-subtitle {
    font-size: 20px;
    line-height: 25px;

    text-align: center;

    color: rgb(220, 236, 255);
}

.landing6-section2-body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;

    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 1%;

    border-radius: 10px;

    background-color: whitesmoke;
}

.landing6-section2-body-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    padding: 20px 20px;
}

/*for the first and second children of landing6-section2-body */
.landing6-section2-body-item:nth-child(1),
.landing6-section2-body-item:nth-child(2) {
    border-right: 1px solid #006aff;
}

.landing6-section2-body-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.landing6-section2-uparrow {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
}

.landing6-section2-uparrow-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    z-index: 1;
}

.landing6-section2-uparrow-body-icon {
    z-index: 1;
}

.landing6-section2-uparrow-image {
    height: 200px;

    margin-bottom: -5%;

    filter: brightness(2);
}

.landing6-section2-right-item-icon {
    z-index: 1;

    background-color: #002147;
    border-radius: 5px;
    padding: 5px;
}

.landing6-section2-uparrow-background {
    position: absolute;
    width: 100vw;
    height: 250vh;

    left: 0px;
    z-index: 0;
    background-image:
        radial-gradient(circle 300px at 60% 70%, rgb(30, 75, 187), transparent 100%),
        radial-gradient(circle 1000px at 110% 60%, rgb(0, 187, 255), transparent 70%);
    ;
}

.landing6navbar-section2-logo-image {
    height: 40px;
    z-index: 1;
}

.landing6-section2-body-item-icon {
    border: 1px solid #006aff;
    background-color: aliceblue;
    border-radius: 5px;
    padding: 5px;

    z-index: 1;
}

.landing6-section2-body-item-content-title {
    font-size: 20px;
    line-height: 25px;

    font-weight: 700;

    color: black;
}

.landing6-section2-body-item-content-subtitle {
    font-size: 15px;
    line-height: 20px;

    color: #525252;
}

.landing6-section2-body-item-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.landing6-section3 {
    padding-top: 5%;
    padding-bottom: 5%;

    position: relative;
}

.landing6-section3-item-header {
    display: flex;
    justify-content: left;
    align-items: center;

    gap: 10px;
}

.landing6-section3-body {
    position: absolute;
}

.landing6-section3-body1 {
    top: 30%;
    left: 78%;

    width: 250px;
}

.landing6-section3-body2 {
    top: 20%;
    left: 10%;

    width: 200px;
}

.landing6-section3-body3 {
    top: 62%;
    left: 10%;

    width: 200px;
}

.landing6-section3-arrow {
    position: absolute;

    width: 150px;
}

.landing6-section3-arrow1 {
    top: 50%;
    left: 74%;
}

.landing6-section3-arrow2 {
    top: 32%;
    left: 16.5%;

    /*flip image horizontally*/
    transform: scaleX(-1);
}

.landing6-section3-arrow3 {
    top: 52%;
    left: 16.5%;

    rotate: 180deg;
}

.landing6-section3-item-content-title {
    font-size: 22px;
    color: rgb(220, 236, 255);
}

.landing6-section3-item-content-subtitle {
    color: rgb(220, 236, 255);
}

.landing6-section3-title {
    font-size: 30px;
    line-height: 35px;

    font-weight: 600;

    text-align: center;

    padding-bottom: 2%;

    color: rgb(220, 236, 255);
}

.landing6-section3-image-container {
    width: 100vw;

    display: flex;
    justify-content: center;
    align-items: center;
}

.landing6-section3-image {
    width: 50vw;

    border: 10px solid aliceblue;
    border-radius: 5px;
}

.landing6-section3-button {
    padding-top: 20px;
}

.landing6-section4 {
    padding-top: 10%;
    padding-bottom: 10%;

    width: 100vw;

    display: grid;
    grid-template-columns: 3fr 2fr;
    align-items: center;

    position: relative;
}

.landing6-section4-background {
    position: absolute;
    z-index: 0;

    top: 0px;
    width: 100vw;
    height: 300vh;

    background-color: rgba(4, 4, 29, 1);
    background-image:
        radial-gradient(circle 1000px at 15% 19%, #003a8a, transparent 10%),
        radial-gradient(circle 1000px at 30% 22%, #19599e, transparent 12%),
        radial-gradient(circle 1000px at 39% 18%, #0b5f81, transparent 7%),
        /*radial-gradient(circle 1000px at 15% 19%, #003a8a, transparent 17%),
        radial-gradient(circle 1000px at 30% 22%, #19599e, transparent 20%),
        radial-gradient(circle 1000px at 39% 18%, #0b5f81, transparent 15%),*/
        radial-gradient(circle 1000px at 90% 20%, #0043a0, transparent 30%),
        radial-gradient(circle 1000px at 65% 40%, #008e9e, transparent 50%),
        radial-gradient(circle 1000px at 20% 68%, #4e3cb2, transparent 40%);
}

.landing6-section4-image {
    width: 75%;

    z-index: 4;

    filter: brightness(2);
}

.landing6-section4-title {
    font-size: 30px;
    line-height: 35px;

    font-weight: 600;

    padding-left: 2%;

    text-align: left;

    color: rgb(220, 236, 255);
}

.landing6-section4-button {
    padding-left: 2%;
}

.landing6-section4-body {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    z-index: 4;
}

.landing6-section4-table {
    color: rgb(220, 236, 255);

    background-color: rgba(4, 4, 29, 1);
    z-index: 2;
}

.landing6-section4-table-row {
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
}

.landing6-section4-table-row-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    padding: 5px 10px;

    white-space: nowrap;
}

.landing6-section4-table-row-title {
    font-weight: 600;
}

.landing6-section4-table {
    border-top: 1px solid #006aff;
    border-left: 1px solid #006aff;
    border-top-left-radius: 5px;

    font-size: 12px;
}

/*all but the last child has border bottom*/
.landing6-section4-table-row:not(:last-child) {
    border-bottom: 1px solid #006aff;
}

/*all but last child as border right*/
.landing6-section4-table-row-cell:not(:last-child) {
    border-right: 1px solid #006aff;
}

.landing6-section4-right {
    padding-right: 10vw;

    display: flex;
    flex-direction: column;
    gap: 20px;

    z-index: 4;
}

.landing6-section4-item {
    position: absolute;

    color: rgb(220, 236, 255);
}

.landing6-section4-item-title {
    font-size: 22px;
    line-height: 30px;

    display: flex;
    justify-content: left;
    align-items: center;

    gap: 10px;

    color: rgb(220, 236, 255);
}

.landing6-section4-item-subtitle {
    color: rgb(220, 236, 255);
}

.landing6-section4-right-item {
    border-top: 1px solid rgb(4, 56, 115);

    padding: 2% 5%;
}

.landing6-section4-item1 {
    top: 40%;
    left: 20%;
}

.landing6-section4-arrow1 {
    top: 42%;
    left: 30%;
}

.landing6-section4-item2 {
    top: 60%;
    left: 50%;
}

.landing6-section4-item3 {
    top: 10%;
    left: auto;
    right: auto;

    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    gap: 5%;
}

.landing6-section4-arrow3 {
    top: 30%;
    left: 60%;
}

.landing6-section4-item4 {
    top: 40%;
    right: 16%;

    width: 180px;
}

.landing6-section4-arrow4 {
    top: 42%;
    right: 30%;
    left: auto;
}

.landing6-section4-arrow {
    position: absolute;
}

.landing6-section4-item-cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.landing6-section4-arrows {
    position: absolute;

    top: 15%;
}

.landing6-section4-arrows-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    width: 25vw;
}

.landing6-section4-content {
    color: rgb(220, 236, 255);

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
}

.landing6-section4-content-left {
    width: 250px;
}

.landing6-section4-content-right {
    display: flex;
    justify-content: right;
    align-items: center;
}

.landing6-section5 {
    padding-top: 15%;
    padding-bottom: 15%;

    width: 100%;

    height: 130vh;

    padding-left: 10%;
    padding-right: 10%;

    background-color: #03002e;

    z-index: 5;
}

.landing6-section5-body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10%;

    z-index: 5;
}

.landing6-section5-section1 {}

.landing6-section5-quote-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding-left: 5%;
    padding-right: 5%;

    z-index: 5;

    height: 55vh;
}

.landing6-section5-section-body {
    display: flex;
    flex-direction: column;

    align-items: left;

    z-index: 5;
}

.landing6-section5-title {
    position: relative;

    font-size: 30px;
    line-height: 35px;

    font-weight: 600;

    text-align: left;

    padding-bottom: 2%;
    padding-left: 5%;

    color: rgb(220, 236, 255);

    z-index: 6;
}

.landing6-section5-section-quote {
    padding-bottom: 4%;

    font-size: 50px;
    line-height: 55px;
    letter-spacing: 0px;
    background: -webkit-linear-gradient(20deg, #dcecff, #0daede, #9cffef, #0d9cde, #dcecff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    z-index: 5;
}

.landing6-section5-section-quote1 {
    padding-bottom: 4%;

    font-size: 50px;
    line-height: 55px;
    letter-spacing: 0px;
    background: -webkit-linear-gradient(120deg, #79ffd9, #0daede, #b0d3ff, #006aff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    z-index: 5;
}

.landing6-section5-section-quote2 {
    padding-bottom: 4%;

    font-size: 50px;
    line-height: 55px;
    letter-spacing: 0px;
    background: -webkit-linear-gradient(20deg, #d659ff, #d8c8ff, #0daede, #b0d3ff, #006aff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    z-index: 5;
}

.landing6-section5-desc {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 30px;

    color: rgb(220, 236, 255);

    z-index: 5;
}

.landing6-section5-image {
    width: 50px;
    height: 50px;
    object-fit: cover;

    border-radius: 50%;
    border: 2px solid #006aff;

    z-index: 5;
}

.landing6-section5-desc-name {
    font-size: 20px;

    white-space: nowrap;
}

.landing6-section5-desc-company {
    font-size: 15px;

    white-space: nowrap;
}

.landing6-section5-desc-title {
    font-size: 15px;
}

.landing6-section5-selection:hover {
    cursor: pointer;

    filter: brightness(2);
}

.landing6-section5-logo {
    height: 30px;

    filter: brightness(0.4);
}

.landing6-section5-logo-selected {
    height: 30px;

    filter: brightness(2);
}

.landing6-section5-section2-quote {
    text-align: left;

    padding-bottom: 2%;

    font-size: 40px;
    line-height: 55px;
    background: -webkit-linear-gradient(120deg, #4e3cb2, #0daede, #b0d3ff, #006aff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.landing6-section5-button {
    z-index: 5;

    padding-top: 10px;
}

.landing6-section5-selection-coantiner {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    gap: 10%;

    padding-left: 5%;
    padding-right: 20%;
    padding-bottom: 5%;
}

.landing6-footer-container {
    padding: 5% 10%;

    display: flex;
    flex-direction: column;
    gap: 50px;

    color: white;

    position: relative;
    z-index: 4;
}

.landing6-footer-body {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    color: white;
}

.landing6-backedby-container {
    width: 100%;

    padding: 5% 10%;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    background-color: #03002e;
}

.landing6-backedby-title {
    font-size: 30px;
    line-height: 35px;

    font-weight: 600;

    color: rgb(220, 236, 255);

    z-index: 5;
}

.landing6-backedby-logos {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    gap: 5%;
}

.landing6-backedby-img {
    height: 60px;

    filter: brightness(0) invert(1);
}

.landing6-section6-container {
    display: flex;
    flex-direction: column;
    gap: 7vh;

    color: white;

    position: relative;
    z-index: 4;

    padding-left: 12vw;
    padding-right: 12vw;

    padding-top: 5vh;
    padding-bottom: 20vh;
}

.landing6-section6-body-container {}

.landing6-section6-title {
    font-size: 30px;
    line-height: 35px;

    font-weight: 600;

    padding-bottom: 1%;

    z-index: 5;

    color: rgb(220, 236, 255);
}

.landing6-section6-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.landing6-section6-body-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 50px;

    height: 100%;

    background-color: rgba(4, 4, 29, 1);

    padding: 5% 3%;
    border-radius: 5%;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    border-bottom: 5px solid #006aff;
}

.landing6-section6-body-item-content-title {
    font-size: 100px;
    line-height: 90px;

    font-weight: 400;

    /*color: rgb(220, 236, 255);*/
    /*color: #00ff95;*/

    background: -webkit-linear-gradient(50deg, #79ffd9, #0daede, #b0d3ff, #006aff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.landing6-section6-body-item-content-title1 {
    background: -webkit-linear-gradient(120deg, #79ffd9, #0daede, #b0d3ff, #006aff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.landing6-section6-body-item-content-title2 {
    background: -webkit-linear-gradient(300deg, #79ffd9, #0daede, #b0d3ff, #006aff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.landing6-section6-body-item-content-subtitle {
    font-size: 20px;
    line-height: 25px;

    color: rgb(220, 236, 255);
}

.landing6-section7-container {
    background-color: #03002e;

    padding: 5% 10%;
}

.landing6-section7-title {
    font-size: 30px;
    line-height: 35px;

    font-weight: 600;

    color: rgb(220, 236, 255);
}

.landing6-news-container {
    position: relative;

    z-index: 5;

    width: 100vw;
    padding-top: 10vh;
    padding-bottom: 20vh;
    padding-left: 30%;
    padding-right: 30%;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.landing6-news-title {
    font-size: 30px;
    line-height: 35px;

    font-weight: 600;

    color: rgb(220, 236, 255);

    z-index: 5;
}

.landing6-news-subtitle {
    font-size: 20px;
    line-height: 25px;

    color: rgb(220, 236, 255);

    z-index: 5;
}

.landing6-news-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.landing6-news-image {
    width: 100%;

    border-radius: 20px;

    box-shadow: 0px 0px 10px 2px #E4EFDE;
}

.landing6-news-image:hover {
    cursor: pointer;

    box-shadow: 0px 0px 10px 5px #E4EFDE;
}

.landing6-news-buttons {
    padding-top: 5%;

    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.landing6-part1-ending-header {
    font-size: 70px;
    line-height: 80px;

    text-align: center;

    color: rgb(220, 236, 255);

    z-index: 4;
}

.landing6-part1-ending-container {
    display: flex;
    flex-direction: column;
    gap: 10vh;

    width: 100vw;

    padding-left: 10%;
    padding-right: 10%;

    padding-top: 5%;
    padding-bottom: 5%;

    color: #006AFF;

    z-index: 4;
}

.landing6-part1-ending-body {
    z-index: 4;

    background-color: white;
    border-radius: 20px;
    padding: 3% 5%;

    display: flex;
    flex-direction: column;
    gap: 40px;

    padding-bottom: 10%;

    background-image:
        radial-gradient(circle 1000px at 105% -5%, #0073b1, transparent 40%),
        radial-gradient(circle 1000px at 10% 10%, #4e3cb2, transparent 40%),
        linear-gradient(180deg, rgb(0, 0, 30), rgba(4, 4, 29, 1));
}

.landing6-part1-ending {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5%;

    z-index: 4;
}

.landing6-part1-ending-left {
    display: flex;
    flex-direction: column;
    align-items: right;

    gap: 20px;

    padding: 10% 10%;

    border-radius: 20px;

    background-color: rgba(4, 4, 29, 1);
    box-shadow: 0px 0px 5px 1px #006AFF;
}

.landing6-part1-ending-title {
    font-size: 100px;
    line-height: 90px;
    font-weight: 200;

    text-align: right;
}

.landing6-part1-ending-subtitle {
    font-size: 25px;
    line-height: 30px;

    text-align: right;

    color: rgb(220, 236, 255);
}

.landing6-part1-ending-right {
    display: flex;
    flex-direction: column;
    align-items: left;

    gap: 20px;

    padding: 10% 10%;

    border-radius: 20px;

    background-color: rgba(4, 4, 29, 1);
    box-shadow: 0px 0px 5px 1px #006AFF;
}

.landing6-part1-ending-right-title {
    font-size: 100px;
    line-height: 90px;
    font-weight: 200;

    color: #006AFF;

    text-align: left;
}

.landing6-part1-ending-right-subtitle {
    font-size: 25px;
    line-height: 30px;
    text-align: left;

    color: rgb(220, 236, 255);
}

.landing6-part2 {
    position: relative;
}

.landing6-part2-background {
    position: absolute;
    z-index: 0;

    width: 100vw;
    height: 100%;

    background-color: #03002e;

    background-image:
        /*radial-gradient(ellipse 1000px 500px at 25% 45%, #006f91, transparent 70%),*/
        radial-gradient(circle 700px at 60% 65%, #000899, transparent 70%),
        radial-gradient(circle 1000px at 30% 95%, #35007b, transparent 50%),
        linear-gradient(rgba(4, 4, 29, 1) 0%, transparent 10%);
}

.landing6-section3-image-container-mobile {
    display: none;
}

.landing6-section3-button-mobile {
    display: none;
}

@media (max-width: 1300px) {
    .landing6-section6-body-item {
        padding: 2% 2%;
    }

    .landing6-part1-ending-title {
        font-size: 80px;
        line-height: 70px;
    }

    .landing6-part1-ending-right-title {
        font-size: 80px;
        line-height: 70px;
    }
}


@media (max-width: 1200px) {
    .landing6-section1-left-title {
        font-size: 35px;
        line-height: 40px;
    }

    .landing6-section1-left {
        gap: 20px;
    }

    .landing6-section1-background {
        background-image: radial-gradient(circle 500px at 5% 10%, #006aff, #0a5ab8 15%, #131832 35%, transparent 50%),
            radial-gradient(circle 500px at 70% 40%, #0daede, transparent 50%),
            radial-gradient(circle 250px at 50% 70%, #4e3cb2, transparent 50%);
    }

    .landing6-section3-arrow {
        width: 75px;
        height: 75px;
    }

    .landing6-section3-body2 {
        left: 5%;
    }

    .landing6-section3-body3 {
        left: 5%;
    }

    .landing6-section6-body-item-content-title {
        font-size: 60px;
        line-height: 70px;
    }

    .landing6-section5-button {
        padding-top: 10px;
    }

    .landing6-part1-ending-title {
        font-size: 70px;
        line-height: 80px;
    }

    .landing6-part1-ending-right-title {
        font-size: 70px;
        line-height: 80px;
    }
}


@media (max-width: 900px) {
    .landing6navbar-logo-image {
        height: 20px;
    }

    .landing6-section1 {
        padding-top: 20%;

        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .landing6-section1-left {
        width: 100%;
        padding-left: 10%;
        padding-right: 10%;
    }

    .landing6-section1-left-title {
        font-size: 30px;
        line-height: 40px;
    }

    .landing6-section1-left-subtitle {
        font-size: 20px;
        line-height: 30px;
    }

    .landing6-header-menu-button {
        font-size: 15px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .landing6-header-menu-button2 {
        font-size: 15px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .landing6-section1-right {
        padding-top: 10%;
        width: 100%;
    }

    .landing6-section1-video {
        width: 90%;
    }

    .landing6-section1-logo {
        height: 25px;

        padding-left: 10px;
        padding-right: 10px;
    }

    .landing6-section1-logos-title {
        font-size: 15px;
        line-height: 30px;
    }

    .landing6-section2 {
        display: flex;
        flex-direction: column-reverse;
    }

    .landing6-section2-top {
        padding-right: 10%;
    }

    .landing6-section2-top-title {
        font-size: 20px;
        line-height: 25px;
    }

    .landing6-section2-item-title {
        font-size: 18px;
    }

    .landing6-section2-item-subtitle {
        font-size: 15px;
    }

    .landing6-section2-uparrow-background {
        width: 100vw;
        height: 200vh;

        background-image:
            radial-gradient(circle 150px at 60% 70%, rgb(30, 75, 187), transparent 100%),
            radial-gradient(circle 500px at 110% 60%, rgb(0, 187, 255), transparent 70%);
    }

    .landing6-section2-right {
        padding-bottom: 5%;
    }

    .landing6-section2-image {
        height: auto;
        width: 60vw;
    }

    .landing6-section3-arrow {
        display: none;
    }

    .landing6-section3-body {
        position: initial;

        width: 80%;

        margin-left: 10%;
        margin-right: 10%;

        padding: 2% 5%;

        border-top: 1px solid #043873;
    }

    .landing6-section3-button {
        display: none;
    }

    .landing6-section3-item-content-title {
        font-size: 18px;
    }

    .landing6-section3-item-content-subtitle {
        font-size: 15px;

        padding-left: 5%;
    }

    .landing6-section3-image-container {
        display: none;
    }

    .landing6-section3-image-container-mobile {
        display: flex;
        justify-content: center;
        align-items: center;

        padding-bottom: 5%;
    }

    .landing6-section3-button-mobile {
        display: flex;
        padding-left: 15%;
        padding-bottom: 5%;
    }

    .landing6-section3-title {
        font-size: 20px;
        line-height: 25px;

        text-align: left;

        padding-left: 15%;
    }

    .landing6-section4 {
        display: flex;
        flex-direction: column;
    }

    .landing6-section4-body {
        padding-bottom: 5%;
    }

    .landing6-section4-title {
        font-size: 20px;
        line-height: 25px;

        padding-left: 10%;
    }

    .landing6-section4-button {
        padding-left: 10%;
    }

    .landing6-section4-item-title {
        font-size: 18px;
        line-height: 25px;
    }

    .landing6-section4-item-subtitle {
        font-size: 15px;
        line-height: 20px;
    }

    .landing6-section4-right {
        width: 100%;

        padding-top: 5%;
        padding-left: 10%;
        padding-right: 10%;
    }

    .landing6-section4-right-item {
        padding: 2% 10%;

        width: 100%;

        border-top: 1px solid #043873;
    }

    .landing6-news-container {
        padding-left: 20%;
        padding-right: 20%;
    }

    .landing6-news-title {
        font-size: 20px;
        line-height: 25px;
    }

    .landing6-news-subtitle {
        font-size: 15px;
        line-height: 20px;
    }

    .landing6-part1-ending-header {
        font-size: 30px;
        line-height: 35px;

        font-weight: 600;
    }

    .landing6-part1-ending-title {
        font-size: 50px;
        line-height: 60px;
    }

    .landing6-part1-ending-subtitle {
        font-size: 20px;
        line-height: 25px;
    }

    .landing6-part1-ending-right-title {
        font-size: 50px;
        line-height: 60px;
    }

    .landing6-part1-ending-right-subtitle {
        font-size: 20px;
        line-height: 25px;
    }

    .landing6-part1-ending-right {
        background-image: none;
    }

    .landing6-part1-ending {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .landing6-part1-ending-body {
        gap: 20px;
    }

    .landing6-section5 {
        height: 80vh;
    }

    .landing6-section5-title {
        font-size: 20px;
        line-height: 25px;
    }

    .landing6-section5-selection-coantiner {
        padding-left: 10%;
        padding-right: 10%;

        flex-wrap: wrap;

        row-gap: 10px;
    }

    .landing6-section5-quote-container {
        height: 30vh;
    }

    .landing6-section5-logo {
        height: 25px;
    }

    .landing6-section5-section-quote {
        font-size: 20px;
        line-height: 25px;
    }

    .landing6-section5-section-quote1 {
        font-size: 20px;
        line-height: 25px;
    }

    .landing6-section5-section-quote2 {
        font-size: 20px;
        line-height: 25px;
    }

    .landing6-section5-desc {
        flex-wrap: wrap;
        row-gap: 10px;
    }

    .landing6-section5-desc-name {
        font-size: 15px;
    }

    .landing6-section5-logo-selected {
        height: 25px;
    }

    .landing6-section6-title {
        font-size: 20px;
        line-height: 25px;
    }

    .landing6-section6-body {
        flex-wrap: wrap;
        gap: 5vh;
        align-items: center;
        justify-content: center;
    }

    .landing6-section6-body-item-content-title {
        font-size: 50px;
        line-height: 60px;
    }

    .landing6-section6-body-item {
        padding: 5% 5%;
        gap: 50px;
    }

    .landing6-backedby-title {
        font-size: 20px;
        line-height: 25px;
    }

    .landing6-backedby-logos {
        flex-wrap: wrap;
        row-gap: 10px;
    }

    .landing6-backedby-img {
        height: 40px;
    }

    .landing6-footer-container {
        display: flex;
        flex-direction: column;
    }

    .landing6-footer-body {
        display: flex;
        flex-wrap: wrap;

        gap: 20px;
    }

    .landing2-footer {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        row-gap: 20px;
    }

    .landing6navbar-conatiner {
        top: 5px;

        height: 40px;

        padding-left: 20px;
        padding-right: 20px;

        border-radius: 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .landing6navbar-mobile-menu {
        position: fixed;

        top: 0px;

        height: 100vh;
        width: 100vw;

        overflow: hidden;

        z-index: 11;

        background-color: rgb(33, 37, 37);

        padding-top: 150px;
        padding-left: 10%;
        padding-right: 10%;

        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .landing6-backedby-container {
        padding-bottom: 20%;
    }
}

@media (max-width: 450px) {
    .landing6-section1 {
        padding-top: 30%;
    }

    .landing6-part1-ending-header {
        font-size: 15px;
        line-height: 20px;
    }

    .landing6-part1-ending-title {
        font-size: 40px;
        line-height: 50px;
    }

    .landing6-section5 {
        height: auto;

        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .landing6-section5-quote-container {
        height: auto;

        padding-bottom: 20%;
        gap: 20px;
    }
}