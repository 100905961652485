body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  /*font-family: "Gellix", Arial, Helvetica, sans-serif !important;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(4, 4, 29, 1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 5px;
}

.active-button {
  border-radius: 5px;
  background-color: #006AFF;
  color: white;
  border: 1px solid #006AFF;

  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  transition-duration: 0.3s;
}

.active-button:hover {
  cursor: pointer;
  background-color: rgb(13, 69, 153);
  border-color: rgb(13, 69, 153);
}

.active-button-small {
  font-size: small;
  border-radius: 5px;
  background-color: #006AFF;
  color: white;
  border: 1px solid #006AFF;

  padding: 2px;
  padding-left: 5px;
  padding-right: 5px;
  transition-duration: 0.3s;
}

.active-button-small:hover {
  cursor: pointer;
  background-color: rgb(13, 69, 153);
  border-color: rgb(13, 69, 153);
}

.active-button-mid {
  align-items: center;
  border-radius: 5px;
  background-color: #006AFF;
  color: white;
  border: 1px solid #006AFF;

  font-size: 15px;
  height: 32px;
  line-height: 30px;

  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  transition-duration: 0.3s;
}

.active-button-mid:hover {
  cursor: pointer;
  background-color: rgb(13, 69, 153);
  border-color: rgb(13, 69, 153);
}

.disabled-button {
  border-radius: 5px;
  border: 1px solid lightgray;
  background-color: gainsboro;
  color: whitesmoke;
}

.default-button {
  background-color: white;
  border-radius: 5px;
  border: 1px solid rgb(204, 204, 204);

  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  transition-duration: 0.3s;
}

.default-button:hover {
  border-color: rgb(167, 166, 176);
  background-color: whitesmoke;
  cursor: pointer;
}

.default-button-small {
  font-size: small;
  background-color: white;
  border-radius: 5px;
  border: 1px solid rgb(204, 204, 204);

  padding: 2px;
  padding-left: 5px;
  padding-right: 5px;
  transition-duration: 0.3s;
}

.default-button-small:hover {
  border-color: rgb(167, 166, 176);
  background-color: whitesmoke;
  cursor: pointer;
}


.input-save-button {
  border: none;
  height: stretch;
  border-left: 1px solid lightgray;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #006AFF;
  color: white;
}

.input-save-button:hover {
  background-color: rgb(13, 69, 153);
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #006AFF;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.close-button {
  background-color: inherit;
  font-size: 20px;
  border-radius: 5px;
  border: none;
}

.close-button:hover {
  background-color: #F1F1F4;
}

.delete-button {
  color: #232539;
  background-color: white;
  /*color: #F7F8FB;
  background-color: #232539;
  border: 1px solid #232539;*/
  /*border: 1px solid rgb(167, 166, 171);*/
  border: none;
  border-radius: 5px;
  /*box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;*/
  padding: 6px;
  padding-left: 10px;
  padding-right: 10px;
  width: fit-content;
}

.delete-button:hover {
  cursor: pointer;
  color: #e56b6f;
}

.delete-x {
  outline: none;
  border: none;
  background-color: white;
  color: #232539;
  font-weight: bolder;
  padding: 0px;
}

.delete-x:hover {
  color: #e56b6f;
  cursor: pointer;
}