.hiringbar-container {
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    padding-left: 10%;
    padding-right: 10%;
    position: fixed;
    top: 0px;
    left: 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    width: 100%;

    z-index: 1000;

    text-align: center;

    background-color: #006aff;
    color: whitesmoke;

    font-family: 'Inter', sans-serif !important;
    font-size: 18px;
    font-weight: 400;

    height: 40px;
}

.hiringbar-link {
    color: whitesmoke;
    text-decoration: none;
    font-style: italic;
}

.hiringbar-link:hover {
    color: #FFE492;
}

.hiringbar-text {
    color: whitesmoke;
    text-decoration: none;
}

.hiringbar-text:hover {
    color: #FFE492;
    text-decoration: underline;
    text-decoration-color: #043873;
}

@media only screen and (max-width: 900px) {
    .hiringbar-container {
        font-size: 15px;
    }
}

@media only screen and (max-width: 400px) {
    .hiringbar-container {
        font-size: 12px;
    }
}