.news-container {
    z-index: 1;

    background-color: rgba(4, 4, 29, 1);

    padding: 10%;
}

.news-title {
    font-size: 50px;
    font-weight: 700;
    line-height: 64px;

    color: rgb(220, 236, 255);
}

.news-subtitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;

    color: rgb(220, 236, 255);

    padding-top: 1%;
    padding-bottom: 5%;
}

.news-items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 20px;
    row-gap: 20px;
}

.news-item {
    background-color: rgb(0, 0, 36);
    border: 1px solid rgba(0, 106, 255, 0.25);
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    gap: 10px;

    height: 100%;
}

.news-item:hover {
    cursor: pointer;
    border: 1px solid #006AFF;

    transition-duration: 0.3s;
}

.news-item-image-conatiner {
    display: flex;
    justify-content: center;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    background-color: black;
}

.news-item-image {
    width: 100%;
    height: 100%;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    min-height: 40vh;
    max-height: 40vh;

    object-fit: cover;
}

.news-item-content {
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;

    height: 100%;
}

.news-item-body {
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 10px;
}

.news-item-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;

    color: rgb(220, 236, 255);
}

.news-item-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;

    color: rgb(220, 236, 255);
}

.news-item-link {
    text-decoration: none;
}

.news-item-link:hover {
    text-decoration: none;
}

.news-item-date {
    padding-top: 5px;

    font-size: 14px;
    font-weight: 400;
    line-height: 21px;

    color: rgb(78, 106, 136);
}

@media (max-width: 900px) {
    .news-title {
        font-size: 40px;
        line-height: 50px;
    }

    .news-container {
        padding-top: 20%;
    }

    .news-items {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}