.demorequest-outer-container {
    z-index: 1;

    position: relative;
    /*Lexend Deca*/
    font-family: 'Lexend Deca', sans-serif !important;
    height: 100vh;
    width: 100vw;

    overflow: hidden;

    background-color: rgba(4, 4, 29, 1);
}

.demorequest-container {
    height: 100vh;
    width: 100vw;

    padding-left: 10%;
    padding-right: 10%;

    padding-top: 80px;

    overflow: scroll;
    padding-bottom: 20%;
}

.demorequest-header {
    margin-top: 5vh;
}

.demorequest-header-title {
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
}

.demorequest-header-desc {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
}

.demorequest-body {
    margin-top: 5vh;
}

.demorequest-body-section {
    margin-top: 5vh;
}

.demorequest-body-section-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}

.demorequest-body-input-text {
    margin-top: 2vh;
    width: 100%;
    height: 48px;

    border: 1px solid #E0E0E0;
    border-radius: 4px;

    padding-left: 16px;
    padding-right: 16px;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.demorequest-body-input-file {
    background: rgba(0, 106, 255, 0.25);
}

.demorequest-upload-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    height: 150px;
    width: 500px;

    border: 2px dashed gray;
    background-color: rgb(33, 37, 37);
    border-radius: 5px;
    outline: none;

    color: rgb(220, 236, 255);

    padding: 5% 5%;
}

.demorequest-upload-placeholder:hover {
    cursor: pointer;
    color: #006AFF;

    border: 2px dashed #006AFF;
}

.demorequest-upload-placeholder-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.demorequest-message-input {
    width: 100%;
    height: 50px;
    padding: 10px;
    padding-left: 20px;
    border: none;
    border-radius: 5px;

    background-color: rgb(33, 37, 37);

    border: 1px solid black;

    outline: none;

    color: #006AFF;
}

.demorequest-message-input:hover {
    border: 1px solid #006AFF;
}

.demorequest-message-input:focus {
    border: 1px solid #006AFF;
}

.demorequest-message-input::placeholder {
    color: rgba(0, 106, 255, 0.65);
}

.demorequest-message-input-container {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.demorequest-body {
    gap: 5%;
}


.demorequest-request-button-container {
    margin-top: 5vh;
}

.demorequest-body-file {
    margin-bottom: 5px;

    /*darker off red*/
    color: rgb(220, 236, 255);
}

.demorequest-request-button {
    width: 200px;
    height: 50px;

    border-radius: 5px;
    background-color: #006AFF;
    color: white;
    border: 1px solid #006AFF;

    font-size: 18px;
    font-weight: 500;

    padding: 5px;
    padding-left: 30px;
    padding-right: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.demorequest-request-button:hover {
    cursor: pointer;
    background-color: rgb(13, 69, 153);
    border-color: rgb(13, 69, 153);
}

.demorequest-request-button:active {
    background-color: #0050B2;
    border-color: #0050B2;
}

.demorequest-request-button-disabled {
    width: 200px;
    height: 50px;

    border-radius: 5px;
    background-color: gray;
    color: black;
    border: 1px solid gray;

    font-size: 18px;
    font-weight: 500;

    padding: 5px;
    padding-left: 30px;
    padding-right: 30px;
}

.demorequest-request-button-disabled:hover {
    cursor: not-allowed;

    background-color: gray;
    color: black;
    border: 1px solid gray;
}

.demorequest-request-sucess {
    margin-top: 5vh;
    color: #006AFF;

    font-size: 24px;
    font-weight: 700;
}

.demorequest-request-sending {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    gap: 10px;
}

.demorequest-request-sending-text {
    font-size: 20px;
    font-weight: 500;

    color: rgb(220, 236, 255);
}

.demorequest-body-inputs {
    width: 100%;

    display: flex;
    gap: 20px;
}

@media (max-width: 900px) {
    .demorequest-message-input-container {
        width: 100%;
    }

    .demorequest-upload-placeholder{
        width: 80%;
    }
}